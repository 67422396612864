// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-picker {
	display: flex;
	align-items: center;
	position: absolute;
	top: .5rem;
	right: .5rem;
	z-index: 3;
	> a {
		color: \$grey;
		margin: 0;
		&::before {
			content: '|';
			padding-left: 0.25rem;
		}
		&:first-child::before {
			content: '';
			padding-left: 0;
		}
	}
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/_language-picker.scss"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,kBAAkB;CAClB,UAAU;CACV,YAAY;CACZ,UAAU;CACV;EACC,YAAY;EACZ,SAAS;EACT;GACC,YAAY;GACZ,qBAAqB;EACtB;EACA;GACC,WAAW;GACX,eAAe;EAChB;CACD;AACD","sourcesContent":[".lang-picker {\n\tdisplay: flex;\n\talign-items: center;\n\tposition: absolute;\n\ttop: .5rem;\n\tright: .5rem;\n\tz-index: 3;\n\t> a {\n\t\tcolor: $grey;\n\t\tmargin: 0;\n\t\t&::before {\n\t\t\tcontent: '|';\n\t\t\tpadding-left: 0.25rem;\n\t\t}\n\t\t&:first-child::before {\n\t\t\tcontent: '';\n\t\t\tpadding-left: 0;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
