// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fadein {
  will-change: opacity;
  animation: fadeIn 2000ms;
}
  
.zoomFadeIn {
  will-change: opacity transform;
  transform-origin: center;
  animation: zoomFadeIn 500ms;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/utilities/_animations.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,wBAAwB;EACxB,2BAA2B;AAC7B","sourcesContent":[".fadein {\n  will-change: opacity;\n  animation: fadeIn 2000ms;\n}\n  \n.zoomFadeIn {\n  will-change: opacity transform;\n  transform-origin: center;\n  animation: zoomFadeIn 500ms;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
