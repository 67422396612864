// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
  border-left: 20px solid #d1ff3b;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  color: #ffffff;
  margin: -10px;
  padding: -10px;
}

.image-logo {
  height: 80px;
  width: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/Home.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,+BAA+B;EAC/B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".home {\n  font-family: sans-serif;\n  text-align: center;\n  height: 100vh;\n  width: 100vw;\n  border-left: 20px solid #d1ff3b;\n  z-index: 999;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n  background-color: #000000;\n  color: #ffffff;\n  margin: -10px;\n  padding: -10px;\n}\n\n.image-logo {\n  height: 80px;\n  width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
