// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes zoomFadeIn {
	from {
		transform: scale(0.95);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
}
`, "",{"version":3,"sources":["webpack://./src/assets/scss/base/_keyframes.scss"],"names":[],"mappings":";AACA;CACC,OAAO,UAAU,EAAE;CACnB,KAAK,UAAU,EAAE;AAClB;;AAEA;CACC;EACC,sBAAsB;EACtB,UAAU;CACX;;CAEA;EACC,mBAAmB;EACnB,UAAU;CACX;AACD","sourcesContent":["\n@keyframes fadeIn {\n\tfrom { opacity: 0; }\n\tto { opacity: 1; }\n}\n\n@keyframes zoomFadeIn {\n\tfrom {\n\t\ttransform: scale(0.95);\n\t\topacity: 0;\n\t}\n\n\tto {\n\t\ttransform: scale(1);\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
